module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cultivate ‘24: Community","short_name":"Cultivate ‘24: Community","start_url":"/","display":"minimal-ui","icon":"src/content/site-settings/cultivate24-favicon-rev2.png","include_favicon":true,"description":"Welcome to our vibrant San Jose community!\nMix and mingle with event professionals, hoteliers and diverse partners over delicious bites and handcrafted cocktails inspired by our unique neighborhoods!","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"19a086907420a8ffd230aae52f5717a3"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/node_modules/@openeventkit/event-site/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/@openeventkit/event-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
